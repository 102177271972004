@-moz-document url-prefix() {
  * {
    scrollbar-width: thin !important;
    scrollbar-color: transparent !important;
  }
}

* {
  &::-webkit-scrollbar {
    width: 5px !important;
    height: 5px;
    background-color: rgba(0, 0, 0, 0);
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #33421f66;
    border-radius: 20px;
    border: none;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: rgb(50, 82, 54);
  }
}

body {
  margin: 0;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

// .spin-box {
//   position: fixed !important;
//   right: 0 !important;
//   left: 0 !important;
//   top: 0 !important;
//   bottom: 0 !important;
//   width: 100% !important;
//   height: 100% !important;
//   background-color: rgb(2, 31, 20);
//   z-index: 1000000 !important;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   pointer-events: none !important;
//   cursor: none !important;
//   user-select: none !important;
// }

// .video-box {
//   position: fixed;
//   z-index: 1000000 !important;
//   background: #022115;
//   width: 100%;
//   top: 0;
//   left: 0;
//   pointer-events: none !important;
//   cursor: none !important;
//   user-select: none !important;
//   right: 0;
//   bottom: 0;
//   min-height: 100%;
// }

// .own-paymodal {
//   .ant-modal-content {
//     padding: 0 !important;
//   }
// }

// .own-pagination {
//   margin: 12px auto;

//   .ant-pagination-item-active {
//     background: transparent !important;
//   }

//   .ant-select-selector {
//     background: transparent !important;
//   }

//   .ant-select-dropdown {
//     background: #0b3421 !important;
//     text-align: left;
//   }

//   .ant-select-arrow {
//     color: white !important;
//   }

//   .ant-select-focused {
//     .ant-select-selection-item,
//     .ant-select-arrow {
//       color: #f8d049 !important;
//     }
//   }
// }

// .footer-link {
//   opacity: 0.8;

//   &:hover {
//     opacity: 1;
//   }
// }

// .claim-card {
//   .ant-card-body {
//     padding: 16px !important;
//   }

//   border: none !important;

//   &.active {
//     outline: 1px solid #f8d049 !important;
//   }
// }

// .ant-message-notice-success {
//   .ant-message-notice-content {
//     background: #0b3421 !important;
//     border: 1px solid #f8d049 !important;
//     color: white !important;
//   }
// }

// .ant-message-notice-error {
//   .ant-message-notice-content {
//     background: #0b3421 !important;
//     border: 1px solid #f0012c !important;
//     color: #f0012c !important;
//   }
// }

// .ant-modal-mask {
//   z-index: 85 !important;
// }

// .buy-modal {
//   z-index: 85 !important;
// }
